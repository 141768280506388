<template>
  <section class="mssg-section">
    <div class="section-header">
      <h2><i class="bx bx-chat"></i> My Messages</h2>
    </div>
    <div class="messages-container xl-container">
      <div class="mss-headers grid grid-3">
        <p><i class="bx bxs-user"></i>Name</p>
        <p><i class="bx bxs-envelope"></i>Email</p>
        <p><i class="bx bx-chat"></i>Message</p>
      </div>

      <div
        v-for="mssg in documents"
        :key="mssg.id"
        class="single-mssg grid grid-3"
      >
        <p>{{ mssg.name }}</p>
        <p>{{ mssg.email }}</p>
        <p>{{ mssg.message }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import getCollection from "@/composables/getCollection";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Messages",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const { mssgError, documents } = getCollection("messages");

    return { mssgError, documents };
  },
};
</script>

<style scoped>
.mssg-section {
  padding: 100px 20px;
}
.messages-container {
  border-radius: 5px;
  border: 1px var(--primary-color) solid;
}
.mssg-section .mss-headers {
  background: rgb(0, 105, 255);
  color: #fff;
  padding: 10px;
}
.mss-headers i {
  margin-right: 6px;
}
.single-mssg {
  padding: 10px;
}
.single-mssg:nth-child(odd) {
  background: #fff;
}
</style>
