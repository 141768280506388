<template>
  <header>
    <div id="nav">
      <Navbar />
    </div>
  </header>
  <div class="home">
    <section class="section bio-section flex">
      <aside class="bio-img">
        <img src="https://nsanzimanagilbert.com/pro-images/nsanzimana_gilbert.jpg" alt="Nsanzimana Gilbert" />
      </aside>
      <aside class="bio-content">
        <div class="bio-intro">
            <h2>Hello! I am Gilbert Nsanzimana</h2>
            <p style="font-size: 20px; color: #fff;">A Full Stack Web Developer</p>
            <p style="font-size: 20px; color: #c88769;">Winner UNESCO India Africa Hackathon 2022</p>

        </div>
        <div class="bio-stack">
            <p> <span>NodeJs</span>, <span>Express</span>, <span>SQL</span>, <span>MongoDB</span>, <span>Mongoose</span>, <span>Javascript</span>, <span>ReactJs</span>, <span>VueJs</span>,
             <span>WebRTC</span>, <span>Redux</span>, <span>Firebase</span>, <span>HTML5/CSS3</span></p>
             <p> <span>Docker</span>, <span>Git</span></p>
          </div>

        <div class="contact-btns">
          <button @click="scrollToContact">Contact ME</button>
        </div>
      </aside>
    </section>
    <section class="section sk-section">
      
      <div class="sk-container l-container">
        <div class="section-header">
          <div class="pre-section--header">
            <span class="code-dot code-dot--red"></span>
            <span class="code-dot code-dot--yellow"></span>
            <span class="code-dot code-dot--green"></span>
          </div>
          <h2>Tech Stack</h2>
        
        </div>
        <div class="sk-container--stack">
           <div class="techStackCode">
            <span class="code-panctuation">&#8249;</span><span class="code-component">MyTechStack </span><span class="code-panctuation">&#8250;</span>
            </div>
          <ul>
            <div class="tech-container">
              <div class="techStackCode">
                <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
              </div>
                <li><i class="bx bxl-html5"></i>HTML</li>
               <div class="techStackCode">
                  <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
              </div>

            </div>
            <div class="tech-container">
                <div class="techStackCode">
                  <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                </div>
                  <li><i class="bx bxl-css3"></i>CSS3</li>
                 <div class="techStackCode">
                    <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                </div>

            </div>
            <div class="tech-container">
                <div class="techStackCode">
                  <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                </div>
                  <li><i class="bx bxl-javascript"></i>JavaScript</li>
                  <div class="techStackCode">
                    <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                </div>

            </div>
            <div class="tech-container flex">
                  <div class="techStackCode">
                    <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                  </div>
                    <li><i class="bx bxl-nodejs"></i>Node.js</li>
                    <div class="techStackCode">
                      <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                  </div>

              </div>
              <div class="tech-container flex">
                    <div class="techStackCode">
                      <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                    </div>
                       <li><span>MongoDB</span> </li>
                      <div class="techStackCode">
                        <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                    </div>

              </div>
               <div class="tech-container flex">
                      <div class="techStackCode">
                        <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                      </div>
                         <li><i class="bx bxl-vuejs"></i>VueJs</li>
                        <div class="techStackCode">
                          <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                      </div>

                </div>
                <div class="tech-container flex">
                        <div class="techStackCode">
                          <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                        </div>
                           <li><i class="bx bxl-react"></i>React</li>
                          <div class="techStackCode">
                            <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                        </div>

                  </div>
                  <div class="tech-container flex">
                          <div class="techStackCode">
                            <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                          </div>
                            <li><i><CoMysql></CoMysql></i> TypeScript</li>
                            <div class="techStackCode">
                              <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                          </div>

                    </div>
                    <div class="tech-container flex">
                            <div class="techStackCode">
                              <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                            </div>
                              <li><i class='bx bxl-redux' ></i> Redux</li>
                              <div class="techStackCode">
                                <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                            </div>

                      </div>
                       <div class="tech-container flex">
                              <div class="techStackCode">
                                <span class="code-panctuation">&#8249;</span><span class="code-component">Tech </span><span class="code-panctuation">&#8250;</span>
                              </div>
                                <li><i class="bx bxl-firebase"></i>Firebase</li>
                                <div class="techStackCode">
                                  <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">Tech</span> <span class="code-panctuation">&#8250;</span>
                              </div>

                        </div>
          </ul>
          <div class="techStackCode">
              <span class="code-panctuation code-panctuation--left">&#8249;</span><span class="code-panctuation">&#8260;</span><span class="code-component">MyTechStack</span> <span class="code-panctuation">&#8250;</span>
          </div>
          <!-- <button class="contact-btn">Recent Projects</button> -->
          <div class="dot tl"></div>
          <div class="dot tr"></div>
          <div class="dot bl"></div>
          <div class="dot br"></div>
        </div>
      </div>
    </section>
    <section class="pros-section" style="display: none;">
      <div class="rounded-top"></div>

      <div class="section-header">
        <h2>Recent Projects</h2>
      </div>
      <div class="pros-container grid grid-2 l-container">
        <div class="single-pro">
          <div class="pro-cover">
            <img src="@/assets/images/Store-SMS-1.jpg" alt="" />
          </div>
          <div class="pro-desc">
            <h3>Stock & Procurement Management System</h3>
            <span class="pro-status">In production</span>

            <p>
              The web app automates all the company's stock requisition and
              procurement process. Has 4 different User levels. It is in
              production already.
            </p>
            <router-link to="/projects/inventory-mgt-system">
              Read More<i class="bx bx-arrow-back bx-rotate-180"></i>
            </router-link>
          </div>
        </div>
         <div class="single-pro">
            <div class="pro-cover">
              <img src="@/assets/images/MindAnchor-Appointments.png" alt="" />
            </div>
            <div class="pro-desc">
              <h3>Virtual Healthcare System</h3>
              <span class="pro-status">To be launched in May, 2023</span>
              <p>
                Web application used by medical proffesionals to meet with clients
                virtually using the in built real-time video and text chats
                capability.
              </p>
              <router-link to="/projects/web-realtime-communication-app">
                Read More<i class="bx bx-arrow-back bx-rotate-180"></i>
              </router-link>
            </div>
          </div>
        <div class="single-pro">
          <div class="pro-cover">
            <img src="@/assets/images/Cybersecspot-home.jpg" alt="" />
          </div>
          <div class="pro-desc">
            <h3>Cybersecurity News Website</h3>
            <span class="pro-status">In production</span>

            <p>
              Has a content management system to allow logged in users to update
              the site content. It is up and running in production.
            </p>
            <a href="https://cybersecspot.com/"
              >Visit Website<i class="bx bx-arrow-back bx-rotate-180"></i
            ></a>
          </div>
        </div>
       
        <div class="single-pro">
          <div class="pro-cover">
            <img src="@/assets/images/SmartBox-1.jpg" alt="" />
          </div>
          <div class="pro-desc">
            <h3>HV Meter Box Controller</h3>
            <span class="pro-status">In Progress...</span>
            <p>
              This application remotely controls and monitors the smart box
              state (open or closed), as well as managing users access to the
              box.
            </p>
            <router-link to="/projects/smart-box-access-controller">
              Read More<i class="bx bx-arrow-back bx-rotate-180"></i>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="section contact-section l-container">
      <Contact />
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import { CoMysql } from "oh-vue-icons/icons";

export default {
  name: "Home",
  components: {
    Navbar,
    Contact,
    Footer,
    "v-icon": CoMysql
  },

  setup() {
    const scrollToContact = () => {
      document.querySelector(".contact-section").scrollIntoView({
        behavior: "smooth",
      });
    };
    const scrollToSk = () => {
      document.querySelector(".sk-section").scrollIntoView({
        behavior: "smooth",
      });
    };
    return { scrollToContact, scrollToSk };
  },
};
</script>
<style scoped>
.bio-section {
  padding: 200px 20px;
  padding-bottom: 20px !important;
  justify-content: center;
  flex-direction: row-reverse;
  color: #fff;
  /* background-image: url("../assets/images/grid-bg.svg"); */
  padding-bottom: 10rem;
  background-image: linear-gradient(-71deg, rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0));
}
.bio-stack{
  border-top: 1px solid #fff;
  margin-top: 20px;
  padding-top: 10px;
}
.bio-stack p{
  font-size: 12px;
  font-weight: 300;
}

.bio-img {
  height: auto;
  width: 380px;
  position: relative;
  margin-left: 50px;
}
.bio-img img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 100;
}
.bio-img::after {
  content: "";
  /* border: 2px #fff solid; */
  width: 100%;
  height: 100%;
  position: absolute;
  right: -5%;
  bottom: -0%;
  /* background: #000; */
}
.bio-intro h2 {
  font-size: 36px;
}
.bio-intro p {
  font-size: 26px;
  margin-bottom: 50px;
}
.code-tag{
  color: #f5f5f5;
}
button {
  padding: 10px 30px;
  outline: none;
  background: #000;
  color: #fff;
  border: 2px solid #fff;
  margin-top: 20px;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
}
button:hover {
  background: #fff;
  outline: 1px var(--primary-color) solid;
  color: #000;
}

.sk-section {
  background: #000;
  padding: 6rem 0;
  padding-bottom: 10rem;
  color: #000;
  position: relative;
  /* background-image: url("../assets/images/grid-bg.svg"); */
}
.sk-section button {
  border-radius: 0;
}

.sk-section .section-heading {
  position: absolute;
  left: 20%;
  top: 0;
  box-shadow: none;
}
.sk-container {
  /* display: grid; */
  /* border-radius: 20px !important; */
  display: none;
}
.pre-section--header{
  margin-right: 20px;
  position: absolute;
  display: flex;
  left: 20px;
}
.section-header .pre-section--header .code-dot{
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.pre-section--header .code-dot--red{
  background: rgba(255,95,87,255);
}
.section-header .pre-section--header .code-dot--yellow{
  background: #ffbc2d;
}
.section-header .pre-section--header .code-dot--green{
  background: #27c93f;
}
.sk-container div {

  justify-content: space-between;
  /* position: relative; */
  /* background: #f4f4f4; */
}
.sk-section .m-container{
  border-radius: 20px !important;
}
.sk-container .sk-container--stack{
  background: var(--tartiary-color);
  color: #fff;
  padding: 5px 30px;
}
.sk-container .techStackCode{
  margin: 0px 10px;

}
.codeStackComponent span{
  display: inline-block;
}

.code-component{
  color: #ec5975;
  margin: 3px;
}
.code-panctuation{
  color: #ccc;
  font-size: 20px;
}
.code-panctuation--left{
  margin-right: 5px;
}
.sk-container .tech-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.sk-container h3 {
  font-size: 26px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}
.sk-container div p {
  font-size: 16px;
  line-height: 2;
}
.sk-container ul {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  justify-items: start;
}
.sk-container ul li {
  font-weight: 300;
  margin-bottom: 7px;
}
.sk-container ul i {
  margin-right: 3px;
  margin-left: 10px !important;
  transform: scale(1.2);
}
.sk-container ul li span {
  margin-left: 22px;
}
.sk-container ul svg {
  margin-right: 7px;
  position: absolute;
  left: 15px;
}
.sk-container p {
  font-weight: 300;
  font-size: 20px;
}
.sk-container button {
  outline: 1px #fff solid;
  font-weight: 500;
  width: 100%;
}
.sk-container button:hover {
  background: #fff;
  color: var(--primary-color);
  outline: 1px var(--primary-color) solid;
}
.sk-container .dot {
  width: 8px;
  height: 8px;
  background: #f4f4f4;
  border: 1px #000 solid;
  box-shadow: none;
  position: absolute;
  padding: 0;
  border-radius: 50%;
  display: none;
}
/* //Projects Side */
.pros-section {
  padding: 0rem 0;
  padding-bottom: 5rem;
  color: #fff;
  background: #000 ;
  position: relative;
}
.section-header {
  text-align: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 50px;
  color: #fff;
}
.sk-section .section-header{
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2d343c;
  padding-top: 5px;
  position: relative;
  border-radius: 10px 10px 0 0;
}
.sk-section .section-header h2{
  background: #20272f;
  font-size: 20px;
  padding: 5px 40px;
  border-radius: 3px 3px 0px 0px;
}
.pros-section .section-header h2{
  color: #fff;
  position: relative;
  z-index: 100;
}

.pros-container {
  justify-content: center;
  grid-auto-columns: auto;
  grid-gap: 20px;
}
.single-pro {
  background: #f4f4f4;
  border: 0.5px solid #f3f3f3;
  box-shadow: 0 11px 30px rgb(154 161 177 / 20%);
  padding: 10px;
}

.single-pro .pro-cover {
  width: 100%;
  height: 60%;
}
.single-pro .pro-desc {
  padding: 20px 10px;
  color: #000;
}
.single-pro .pro-desc p {
  display: none;
  font-weight: 300;
  margin: 20px 0;
}
.pro-desc a {
  border: 0.5px var(--tartiary-color-dark) solid;
  padding: 5px 30px;
  text-transform: uppercase;
  font-size: 0.8rem;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  display: block;
  color: #000;
}
.pro-desc a:hover {
  background: var(--tartiary-color-dark);
  color: #fff;
}
.single-pro img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tl {
  left: -4px;
  top: -4px;
}
.tr {
  right: -4px;
  top: -4px;
}
.bl {
  bottom: -4px;
  left: -4px;
}
.br {
  bottom: -4px;
  right: -4px;
}

.footer-section {
  padding: 30px 0;
  background: #000;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .bio-section{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .bio-intro h2 {
    font-size: 25px;
  }
  .bio-intro p {
    font-size: 15px;
  }
  .bio-img {
    width: 250px;
    height: 380px;
    /* border-radius: 50%; */
  }
  .bio-img img{

  }
  .nav{
    justify-content: center;
  }

  .sk-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 649px) {
  .bio-section {
    flex-direction: column;
  }
  .bio-img {
    margin-left: 0;
    padding: 5px;
    /* border: 1px var(--primary-color) solid; */
  }
  .bio-img::after {
    display: none;
  }
  .bio-content {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
