<template>
  <header>
    <div id="nav">
      <Navbar />
    </div>
  </header>
  <h2>404 Not Found</h2>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/SubNavbar.vue";
export default {
  name: "NotFound",
  components: { Navbar, Footer },
};
</script>

<style></style>
