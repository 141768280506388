<template>
  <button class="carousel-control left" @click="$emit('prev')">
    <i class="bx bx-chevron-left"></i>
  </button>
  <button class="carousel-control right" @click="$emit('next')">
    <i class="bx bx-chevron-right"></i>
  </button>
</template>

<script>
export default {
  emits: ["prev", "next"],
};
</script>

<style scoped>
.carousel-control {
  border: none;
  display: inline-block;
  position: absolute;
  top: calc(50% - 25px);
  color: #000;
  cursor: pointer;
  z-index: 1000;
  font-size: 2rem;
  background: inherit;
}
.left {
  left: 0px;
}
.right {
  right: 0;
}

@media screen and (max-width: 767px) {
  .carousel-control {
    height: 30px;
    width: 50px;
    display: none;
  }
}
</style>
