<template>
  <header>
    <div id="nav">
      <Navbar />
    </div>
  </header>
  <section class="blogs-container">
    <div class="blogs-container-header">
      <img src="https://images.unsplash.com/photo-1488998427799-e3362cec87c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="">
      <div class="header-overlay"></div>
      <div class="header-content">
        <h2>Welcome to my pen</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias earum commodi minus natus nesciunt qui rerum tempora possimus incidunt pariatur?</p>
      </div>

    </div>
  </section>
  <section class="section blogs-section">
    <div class="blogs-nav">
    </div>
    <div class="card-container l-container grid grid-3">
      <div class="card" v-for="blog  in blogs">
        <div class="card-cover">
          <img v-bind:src="blog.cover" v-bind:alt="blog.title">

        </div>
        <a :href="blog.url">
          <div class="card-content">
            <p class="card-tag">{{ blog.category }}</p>
            <h2>{{ blog.title }}</h2>
            <p>{{ blog.description }}</p>

          </div>

        </a>
        
        
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Blogs",
  components: { Navbar },
  setup() {
    
    const blogs = [{
      id: 1, title: "Backend Development", description: "Ever wonder what goes on behind the scenes of a website? What really is behind there?", cover: "https://plopdo.com/wp-content/uploads/2021/10/What-is-back-end-development-2.jpg", category: "Web Development"},
      {
        id: 2, title: "Common Types of Phishing", description: "75 percentage of organizations around the world experienced some kind of phishing", cover: "https://www.ncsc.gov.uk/images/Phishing-vector%20-%20Copy.png?mpwidth=545&mlwidth=737&twidth=961&dwidth=635&dpr=1.25&width=1536", url: "https://answers.mak.ac.ug/security/phishing-common-types-and-tips-prevention", category: "security"
      },
      {
        id: 3, title: "The Good Password", description: "Having a secure online visibility begins with a “good” password", cover: "https://nsanzimanagilbert.com/blogs/the-good-password/img/password-reseting.jpg", url: "https://nsanzimanagilbert.com/blogs/the-good-password/", category: "security"
      }]
    

    return { blogs };
  },
};
</script>

<style scoped>
.blogs-container-header{
  height: 30rem;
  position: relative;
}
.blogs-container-header img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blogs-container-header .header-overlay{
  background: #0000009f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
background-image:  linear-gradient(-71deg, rgb(21, 195, 154), rgb(25, 190, 160) 6%, rgb(25, 190, 160) 7%, rgb(74, 110, 224) 95%)
}
.header-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100;
  color: #fff;
}
.header-content h2{
  font-size: 3rem;
}
.blogs-section{
  padding: 50px 0;

}
.blogs-section .blogs-nav{
  height: 100%;
  /* background: #000000; */
}
.card-container{
  gap: 40px 40px;
  padding: 20px;
}
.card{
  width: 100%;
  text-align: center;
  background: #fff;
  border: 0.7px solid #e0e0f2;
  border-radius: 4px;
  position: relative;
}
.card .card-content .card-tag{
  text-transform: uppercase;
  padding: 3px 6px;
  background: #0056b9;
  display: inline;
  font-size: 10px;
  position: relative;
  color: #fff;
  top: -20px;
}
.card .card-cover{
   height: 200px;
   padding: 0;
}
.card .card-cover img{
  border-radius: 4px 4px 0 0 ;
  height: 100%;
  width: 100%;
  object-fit: cover !important;
}
.card .card-content{
  padding: 0px 20px 40px 20px;
}
</style>
