<template>
  <div class="carousel">
    <div class="carousel-inner">
      <carousel-item
        v-for="(slide, index) in slides"
        :key="`item-${index}`"
        :slide="slide"
        :current-slide="currentSlide"
        :index="index"
        :direction="direction"
        @mouseenter="stopSlideTimer"
        @mouseout="startSlideTimer"
      ></carousel-item>
    </div>
    <carousel-controls
      v-if="controls"
      @prev="prev"
      @next="next"
    ></carousel-controls>
    <carousel-indicators
      v-if="indicators"
      :total="slides.length"
      :current-index="currentSlide"
      @switch="switchSlide($event)"
    ></carousel-indicators>
  </div>
</template>

<script>
import CarouselItem from "./CarouselItem.vue";
import CarouselControls from "./CarouselControls.vue";
import CarouselIndicators from "./CarouselIndicators.vue";
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 5000,
    },
  },

  components: { CarouselItem, CarouselControls, CarouselIndicators },

  methods: {
    setCurrentSlide(index) {
      this.currentSlide = index;
    },

    prev(step = -1) {
      const index =
        this.currentSlide > 0
          ? this.currentSlide + step
          : this.slides.length - 1;
      this.setCurrentSlide(index);
      this.direction = "left";
      this.startSlideTimer();
    },
    _next(step = 1) {
      const index =
        this.currentSlide < this.slides.length - 1
          ? this.currentSlide + step
          : 0;
      this.setCurrentSlide(index);
      this.direction = "right";
    },

    next(step = 1) {
      this._next(step);
      this.startSlideTimer();
    },

    startSlideTimer() {
      this.stopSlideTimer();
      this.slideInterval = setInterval(() => {
        this._next();
      }, this.interval);
    },

    stopSlideTimer() {
      clearInterval(this.slideInterval);
    },
    switchSlide(index) {
      const step = index - this.currentSlide;
      if (step > 0) {
        this.next(step);
      } else {
        this.prev(step);
      }
    },
  },

  data: () => ({
    currentSlide: 0,
    slideInterval: null,
    direction: "right",
  }),

  mounted() {
    this.startSlideTimer();
  },
  beforeUnmount() {
    this.stopSlideTimer();
  },
};
</script>

<style scoped>
.carousel {
  display: flex;
  justify-content: center;
  position: relative;
  height: 500px;
  max-width: 1000px;
  margin: 0 auto;
}
.carousel-inner {
  position: relative;
  width: 92%;
  height: 90%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .carousel {
    height: 390px;
  }
  .carousel-inner {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .carousel {
    height: 200px;
  }
  .carousel-inner {
    width: 100%;
  }
}
</style>
