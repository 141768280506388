<template>
  <div class="carousel-indicators">
    <button
      class="carousel-indicator-item"
      v-for="(item, index) in total"
      :key="index"
      :class="{ active: currentIndex === index }"
      @click="$emit('switch', index)"
    ></button>
  </div>
</template>

<script>
export default {
  emits: ["switch"],
  props: ["total", "currentIndex"],
};
</script>

<style scoped>
.carousel-indicators {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  /* background: #999; */
  z-index: 2;
}
.carousel-indicator-item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background: #000;
  margin: 0.2em 0.7rem;
  opacity: 0.3;
  cursor: pointer;
}
.active {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .carousel-indicator-item {
    margin: 0.2em 0.3rem;
  }
}
</style>
