import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDCHU1ruWku6dXKH0fgg0SaEne4_K-h-FI",
  authDomain: "portifolio-6a1c4.firebaseapp.com",
  projectId: "portifolio-6a1c4",
  storageBucket: "portifolio-6a1c4.appspot.com",
  messagingSenderId: "895738509746",
  appId: "1:895738509746:web:9b8d097dca0906ca3cccab",
};

firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, timestamp, projectStorage };
