<template>
  <header>
    <div id="nav">
      <SubNavbar />
    </div>
  </header>
  <section class="proShowCase xl-container">
    <div class="proName tc">
      <h3>Stock and Procurement Management System</h3>
    </div>
    <div class="proSlider">
      <Slider :images="images" />
    </div>
    <div class="proDesc l-container">
      <p>
        This system covers all the processes involved in stock requisition and
        procurement. It is used by one of the prominent clinical labs in
        Kampala.
      </p>

      <h3>Key Features</h3>
      <div class="features">
        <li><i class="bx bx-right-arrow-alt"></i>5 user levels</li>
        <li>
          <i class="bx bx-right-arrow-alt"></i>Dashboard (For summary and
          analytics of activities)
        </li>
        <li>
          <i class="bx bx-right-arrow-alt"></i>PDF generation for receipts
        </li>
        <li>
          <i class="bx bx-right-arrow-alt"></i>Timely monitoring of matrial
          quantities to indicate action points
        </li>
        <li>
          <i class="bx bx-right-arrow-alt"></i>Account & Password self-apdate
        </li>
      </div>

      <p>
        The application is further divided into 4 Modules (sub-aaplications) and
        each module is accessed depending upon the rights and permissions of the
        user. The splitting of the app also allows for scalability in the future
        in case a new module is to be added.
      </p>
      <p>
        The company provided me with a paper work accompanied with several
        meetings to understand how the manual procedures are caried out.
      </p>
      <p>
        I designed the entire application from the beginning to the end(thumbs
        up to myself👍👍). I designed the custom REST API first, modeled
        databases, then linked the two with the front-end.
      </p>
      <p>
        The key technologies and languages used include HTML, CSS, Vanilla
        Javascript, Pug, NodeJs, Express, MongoDb, Mongoose. And numerous NPM
        packages.
      </p>
      <p>
        Overall, the application is a reliable solution especially in the
        current era of hybrid workforce. 4 offices are now enables to render
        services efficiently remotely.
      </p>
    </div>
  </section>
  <section class="section contact-section l-container">
    <Contact />
  </section>
</template>

<script>
import SubNavbar from "@/components/SubNavbar";
import Slider from "@/components/Slider.vue";
import Contact from "@/components/Contact.vue";

import { ref } from "@vue/reactivity";
export default {
  name: "SMS",
  components: { Slider, Contact, SubNavbar },
  setup() {
    const images = ref([
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-1.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-2.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-4.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-3.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-5.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-6.jpg",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-7.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-8.png",
      "https://nsanzimanagilbert.com/pro-images/Store-SMS-9.png",
    ]);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    window.setTimeout(scrollToTop(), 0);

    return { images, scrollToTop };
  },
};
</script>

<style scoped>
.proShowCase {
  padding: 100px 0;
}
.proName {
  font-size: 2rem;
}
.proDesc {
  margin-top: 50px;
}
.proDesc p {
  margin-bottom: 30px;
}
.features {
  margin: 20px 0;
}
h3 {
  margin-bottom: 10px;
}
li {
  position: relative;
}
li::before {
  position: absolute;
  content: "";
}
@media screen and (max-width: 768px) {
  .proName {
    font-size: 1.2rem;
  }
}
</style>
