<template>
  <nav class="nav flex">
    <router-link to="/">
      <h1 translate="no">Nsanzimana Gilbert</h1>
    </router-link>

    <div  class="burger-btn" @click="showBurgerNav" style="display: none
    ;">
      <i class="bx bx-menu"></i>
    </div>
    <ul class="main-nav nav-links flex" style="display: none
    ;">
      <router-link to="/" class="nav-link">Home</router-link>
      <!-- <li class="nav-link">Home</li> -->

      <li @click="scrollToPros" class="nav-link">Projects</li>
      <li class="nav-link"> <a href="/blogs">Blogs</a></li>
    </ul>
  </nav>
  <ul class="burger-nav nav-links flex" style="display: none
  ;">
    <li class="nav-link">Home</li>
    <li @click="scrollToPros" class="nav-link">Projects</li>
    <li class="nav-link"> <a href="/blogs">Blogs</a> </li>
  </ul>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const showBurgerNav = () => {
      // document.querySelector(".burger-nav").classList.toggle("show-burger-nav");
    };
    const scrollToSk = () => {
      document.querySelector(".sk-section").scrollIntoView({
        behavior: "smooth",
      });
    };
    const scrollToPros = () => {
      document.querySelector(".pros-section").scrollIntoView({
        behavior: "smooth",
      });
    };

    return { showBurgerNav, scrollToSk, scrollToPros };
  },
};
</script>

<style scoped>
.nav {
  background: #000;
  color: #fff;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.nav-link:not(:last-child) {
  margin-right: 20px;
}
.nav-link {
  position: relative;
  cursor: pointer;
}
.nav-link::after {
  content: "";
  background: #fff;
  height: 2px;
  width: 0%;
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: ease-in-out 0.2s;
}
.nav-link:hover::after {
  width: 100%;
}
.burger-btn {
  cursor: pointer;
}
.burger-btn i {
  transform: scale(1.5);
}
.burger-nav {
  transition: ease-in-out 0.5s;
  height: 0px;
  width: 100%;
  overflow: hidden;
  display: block;
  background: var(--tartiary-color);
}

.show-burger-nav {
  margin-top: 3px;
  background: var(--tartiary-color);

  display: block;
  height: 120px;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  font-size: 15px;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .nav .burger-btn {
    display: none;
  }
  .show-burger-nav {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .nav .main-nav {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .nav h1 {
    font-size: 20px;
  }
}
</style>
