<template>
  <router-view />
  <section class="section footer-section">
    <Footer />
  </section>
</template>

<script>
import "@/assets/css/styles.css";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Navbar, Footer },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4;
}
.footer-section {
  padding: 30px 0;
  background: #000;
}
</style>
