<template>
  <header>
    <div id="nav">
      <SubNavbar />
    </div>
  </header>
  <section class="proShowCase xl-container">
    <div class="proName tc">
      <h3>HV Meter Box Controller</h3>
    </div>
    <div class="proSlider">
      <Slider :images="images" />
    </div>
    <div class="proDesc l-container">
      <p>
        The idea came from Uganda's main electricity distribution company,
        <a href="https://www.umeme.co.ug/">UMEME</a>, and it is to provide means
        of securely monitoring and controlling access to the company's High
        Voltage Meters (Over 9000), to minimize issues with power theft and
        access controls.
      </p>

      <h3>How it works</h3>
      <div class="features">
        <p>
          The project entailed designing a safe meter box and a web application
          for timely monitoring of the box state (open or closed) and access
          request and approvals.
        </p>
        <p>
          The application provides a platform for making access requests as well
          as granting or denying access by the responsible staff. It also
          controls the meter box by issuing "open" commands when access has been
          granted by the staff in charge
        </p>
        <p>
          I worked with a team of two gentlemen,
          <a
            href="https://twitter.com/musdalifa97?s=20&t=-775YIG1aKBUY4UCEHSRUA"
            target="_blank"
            >Musdalifa Parwoth</a
          >
          and
          <a href="mailto:richardtanyoomwa@gmail.com ">Richard Tanyoomwa</a> who
          are really good in the area of embedded systems. They designed the
          smart box (both the hardware and the software). I then designed the
          web interface and the databases to for exchange of messages between
          the meter box and the web.
        </p>
        <p>
          I used HTML, CSS, VueJs, and Firebase (Provided by Google as
          backend-as-a-service)
        </p>
      </div>
    </div>
  </section>
  <section class="section contact-section l-container">
    <Contact />
  </section>
</template>

<script>
import Slider from "@/components/Slider.vue";
import Contact from "@/components/Contact.vue";
import SubNavbar from "@/components/SubNavbar.vue";

import { ref } from "@vue/reactivity";
export default {
  name: "SMS",
  components: { Slider, Contact, SubNavbar },
  setup() {
    const images = ref([
      "https://nsanzimanagilbert.com/pro-images/SmartBox-1.jpg",
      "https://nsanzimanagilbert.com/pro-images/SmartBox-2.jpg",
      "https://nsanzimanagilbert.com/pro-images/SmartBox-3.jpg",
      "https://nsanzimanagilbert.com/pro-images/SmartBox-4.jpg",
      "https://nsanzimanagilbert.com/pro-images/SmartBox-5.jpg",
      "https://nsanzimanagilbert.com/pro-images/SmartBox-6.jpg",
    ]);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    window.setTimeout(scrollToTop(), 0);
    return { images };
  },
};
</script>

<style scoped>
.proShowCase {
  padding: 100px 0;
}
.proName {
  font-size: 2rem;
}
.proDesc {
  margin-top: 50px;
}
.proDesc p {
  margin-bottom: 30px;
}
.features {
  margin: 20px 0;
}
h3 {
  margin-bottom: 10px;
}
a {
  color: var(--tartiary-color);
}
li {
  position: relative;
}
li::before {
  position: absolute;
  content: "";
}
@media screen and (max-width: 768px) {
  .proName {
    font-size: 1.2rem;
  }
  .carousel-indicator-item {
    margin: 0.2em 0.4rem;
  }
}
</style>
