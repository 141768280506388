<template>
  <div class="projects">
    <section class="section projects-section l-container">
      <router-link to="/projects/inventory-mgt-system">
        <div class="single-pro">
          <img src="@/assets/images/cybersecspot.jpg" alt="Cybersecspot" />
          <div class="pro-desc">
            <h3>Cybersecspot</h3>
            <p>A Cyber Security newsletter</p>
          </div>
        </div>
      </router-link>

      <div class="single-pro">
        <img
          src="@/assets/images/rcms.jpg"
          alt="Remote Control and Monitoring System"
        />
        <div class="pro-desc">
          <h3>RCMS</h3>
          <p>Remote Control and Monitoring System</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Contact,
    Footer,
  },
};
</script>

<style scoped>
.projects-section {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.single-pro {
  width: 100%;
  background: #fff;
  padding: 10px;
}
.single-pro img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.footer-section {
  padding: 30px 0;
  background: #000;
}
</style>
