import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Messages from "@/views/Messages.vue";
import projects from "@/views/projects/projects.vue";
import projectOne from "@/views/projects/SMS.vue";
import projectTwo from "@/views/projects/SmartBox.vue";
import projectThree from "@/views/projects/webRTC.vue";
import Blogs from "@/views/Blogs.vue"

import NotFound from "@/views/NotFound.vue";

import Login from "@/views/Login.vue";
import { projectAuth } from "../firebase/config";

const requiredAuth = (to, from, next) => {
  let user = projectAuth.currentUser;

  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    beforeEnter: requiredAuth,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
   {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
  },
  {
    path: "/projects",
    name: "projects",
    component: projects,
  },
  {
    path: "/projects/inventory-mgt-system",
    name: "projectOne",
    component: projectOne,
  },
  {
    path: "/projects/smart-box-access-controller",
    name: "projectTwo",
    component: projectTwo,
  },
  {
    path: "/projects/web-realtime-communication-app",
    name: "projectThree",
    component: projectThree,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
