<template>
  <section class="login-section">
    <form @submit.prevent="handleLoginSubmit">
      <h4>Hi Gilbert! Please login here</h4>
      <label for="email">Email</label>
      <input type="email" v-model="email" />
      <label for="password">Password</label>
      <input type="password" v-model="password" />
      <button>Sign In</button>
    </form>
  </section>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import useLogin from "@/composables/useLogin";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";

export default {
  name: "Login",
  components: { Navbar },
  setup() {
    const { login, error, isPending } = useLogin();
    const email = ref("");
    const password = ref("");
    const router = useRouter();

    const handleLoginSubmit = async () => {
      const res = await login(email.value, password.value);
      if (!error.value) {
        router.push({ name: "Messages" });
      }
    };

    return { email, error, password, handleLoginSubmit, isPending };
  },
};
</script>

<style scoped>
.login-section {
  padding: 50px 0;
  position: relative;
  padding-bottom: 200px;
}
form {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 40px 30px;
  border: 1px var(--primary-color) solid;
  max-width: 400px;
  margin: 0 auto;
}
h4 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 40px;
}
input {
  padding: 10px;
  background: #f3f3f3;
  margin-bottom: 20px;
  outline: 0.5px var(--primary-color) solid;
  border: none;
}
label {
  margin-bottom: 3px;
}
button {
  padding: 10px;
  border: none;
  background: var(--primary-color);
  color: #fff;
  text-transform: uppercase;
  height: 45px;
}
</style>
