<template>
  <div class="contact grid grid-2">
    <div>
      <h3>Let's Get in Touch Today!</h3>
      <p>
        You can reach out to me via any convenient means for you, I will respond
        within 24 Hours.
      </p>
      <div class="contact-icons flex"></div>
    </div>
    <form @submit.prevent="handleSubmit">
      <div>
        <input
          type="text"
          id="name"
          name="name"
          placeholder=" "
          class="input"
          v-model="name"
          required
        />
        <label for="name" class="placeholder label">Your Name</label>
      </div>
      <div>
        <input
          type="email"
          id="email"
          name="email"
          placeholder=" "
          class="input"
          v-model="email"
          required
        />
        <label for="email" class="placeholder label">Your Email Address</label>
      </div>
      <div class="message">
        <textarea
          name="message"
          id="message"
          placeholder=" "
          class="input"
          v-model="message"
          required
        ></textarea>
        <label for="message" class="placeholder label">Your Message</label>
      </div>
      <button v-if="!isPending">Send Message</button>
      <button v-else disabled>Sending Message...</button>
      <p v-if="showSentMssg" id="sentMssg">
        Thank you for reaching out! I will respond to you very soon.
      </p>
    </form>
  </div>
</template>

<script>
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import moment from "moment";

import { ref } from "@vue/reactivity";
export default {
  setup() {
    let isPending = ref(false);
    let showSentMssg = ref(false);
    const name = ref("");
    const email = ref("");
    const message = ref("");
    const { error, addDoc } = useCollection("messages");
    const hideSentMssg = () => {
      showSentMssg.value = false;
    };
    const handleSubmit = async () => {
      isPending.value = true;
      await addDoc({
        name: name.value,
        email: email.value,
        message: message.value,
        createdAt: moment().local(timestamp()).format("lll"),
      });
      isPending.value = false;
      if (!error.value) {
        name.value = "";
        email.value = "";
        message.value = "";
        showSentMssg.value = true;
        setTimeout(hideSentMssg, 4000);
      }
    };

    return {
      name,
      email,
      message,
      handleSubmit,
      isPending,
      showSentMssg,
    };
  },
};
</script>

<style scoped>
.contact h3 {
  font-size: 36px;
  margin-bottom: 10px;
}
.contact span {
  display: inline;
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  outline: 1px var(--primary-color) solid;
  cursor: pointer;
}
.contact span:hover {
  background: var(--primary-color);
  color: #fff;
  transition: ease-in-out 0.2s;
}
.contact-icons {
  justify-content: flex-start;
  margin-top: 20px;
}
form {
  background: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 3px #000;
}
form div {
  position: relative;
}
label {
  position: absolute;
  bottom: 9px;
  left: 5px;
  font-size: 12px;
  pointer-events: none;
}
input {
  display: block;
  margin-bottom: 20px;
  height: 35px;
  padding: 5px;
}

button {
  width: 100%;
  height: 35px;
  text-transform: uppercase;
  margin-top: 10px;
  color: #fff;
  background: var(--tartiary-color);

  border: none;
  border-radius: 3px;
}
input,
textarea {
  width: 100%;
  font-family: inherit;
  outline: none;
  border: none;
  border-radius: 3px;
  /* border: 1px #000 solid; */
  box-shadow: 0 0 2px #000;
  /* background: #f4f4f4; */
}
textarea {
  resize: none;
  height: 100px;
  padding: 10px;
}
.message label {
  top: 7px;
}
.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  top: -17px;
  left: 3px;
  color: rgb(201, 0, 0);
  font-size: 10px;
  /* background: #f4f4f4; */
  padding: 2px 4px;
  height: 20px;
}

.input:not(:placeholder-shown) ~ .label {
  display: none;
}
#sentMssg {
  color: var(--color-primary);
  text-align: center;
  margin-top: 10px;
  font-weight: 400;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .contact {
    text-align: center;
  }
  .contact div p {
    display: none;
  }
  .contact .contact-icons {
    display: none;
  }
  /* .contact div:nth-of-type(1){
        order: 2
    } */
  .contact h3 {
    font-size: 25px;
  }
  .contact-icons {
    justify-content: center;
  }
  .contact span {
    width: 30px;
    height: 30px;
  }
  form {
    margin-top: 30px;
    border-radius: 2px;
  }
}
</style>
