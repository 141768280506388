<template>
  <div class="footer l-container">
    <p>&copy; 2023 Nsanzimana Gilbert</p>
    <div>
      <p>
        <a href="mailto:gilbert@nsanzimanagilbert.com" target="_blank"
          ><i class="bx bx-envelope"></i><span>Email</span>
        </a>
      </p>
      <p>
        <a href="https://twitter.com/nsanzimana_G"
          ><i class="bx bxl-twitter"></i> <span>Twitter</span></a
        >
      </p>
      <p>
        <a
          href="https://rw.linkedin.com/in/nsanzimana-gilbert-44a651159"
          target="_blank"
          ><i class="bx bxl-linkedin"></i> <span>Linkedin</span></a
        >
      </p>
      <p>
        <a href="https://github.com/nsanzimanagilbert/"
          ><i class="bx bxl-github"></i> <span>GitHub</span></a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.footer div p {
  display: inline;
}
.footer div p {
  padding: 0 20px;
  position: relative;
}
.footer div p i {
  margin-right: 5px;
}
.footer div p:not(:last-child)::after {
  content: "|";
  color: rgb(255, 255, 255);
  position: absolute;
  right: 0px;
  top: 0;
}

@media screen and (max-width: 650px) {
  .footer p {
    font-size: 12px;
  }
  .footer div p {
    padding: 0 10px;
  }
  .footer div p span {
    display: none;
  }
  .footer div p::after {
    display: none;
  }
}
</style>
