<template>
  <Carousel :slides="slides" :interval="6000" controls indicators></Carousel>
</template>

<script>
import Carousel from "./Carousel/Carousel.vue";
export default {
  components: { Carousel },
  props: ["images"],

  data: (props) => ({
    slides: props.images,
  }),
};
</script>

<style>
.carousel {
  max-width: 100vw;
  height: 20vw;
}
.carousel img {
  width: 100%;
  height: 100%;
  /* flex-basis: 100%; */
  object-fit: contain;
}

@media screen and (max-width: 1024px) {
  .carousel {
    /* height: 350px; */
  }
}
@media screen and (max-width: 768px) {
  .carousel {
    /* height: 250px; */
  }
}
</style>
