<template>
  <header>
    <div id="nav">
      <SubNavbar />
    </div>
  </header>
  <section class="proShowCase xl-container">
    <div class="proName tc">
      <h3>Virtual Healthcare System</h3>
    </div>
    <div class="proSlider">
      <Slider :images="images" />
    </div>
    <div class="proDesc l-container">
       <h3>Overview</h3>
      <p></p>
      <p>
        The system is to bridge the gap in timely healthcare access by providing
        a web-based platform to help patients find and schedule appointments
        with the experts. The system has an in-built video conferencing service.
      </p>
      <p>
          It is divided into 3 parts which are two Progressive Web Applications (PWDs) for the staff and client, and visitors website (built with technologies that are SEO-friendly)
        </p>
      <h3>Tech Stack</h3>
      <p>
        NodeJs, Express, MongoDB, Mongoose, ReactJs, Redux, webRTC, Socket.Io, Vanilla
        Javascript, Pug, Bootstrap CSS
      </p>
       <h3>Key Features</h3>
        <div class="features">
           <li>
              <i class="bx bx-right-arrow-alt"></i>Appointment scheduling and approvals
            </li>
          <li>
             <li>
              <i class="bx bx-right-arrow-alt"></i>In-built video conferencing module
            </li>
            <i class="bx bx-right-arrow-alt"></i>Dashboard (For summary and
            analytics of activities)
          </li>
          <li>
            <i class="bx bx-right-arrow-alt"></i>SMS and email notifications
          </li>
          <li>
              <i class="bx bx-right-arrow-alt"></i>User management module by system administrator
            </li>
         
          <li>
            <i class="bx bx-right-arrow-alt"></i>Account & Password recovery with OTPs (One time passwords)
          </li>
        </div>
        <p></p>

      <div class="pro-coming-soon">
        <p>More Details Coming Soon...🕵️‍♀️</p>
      </div>
    </div>
  </section>
  <section class="section contact-section l-container">
    <Contact />
  </section>
</template>

<script>
import Slider from "@/components/Slider.vue";
import Contact from "@/components/Contact.vue";
import SubNavbar from "@/components/SubNavbar.vue";
import { ref } from "@vue/reactivity";
export default {
  name: "webRTC",
  components: { Slider, Contact, SubNavbar },
  setup() {
    const images = ref([
      "https://nsanzimanagilbert.com/pro-images/MindAnchor-Dashboard.png",
      "https://nsanzimanagilbert.com/pro-images/MindAnchor-Appointments.png",
      "https://nsanzimanagilbert.com/pro-images/MindAnchor-Appointment.png",
      "https://nsanzimanagilbert.com/pro-images/MindAnchor-Staff.png",
      "https://nsanzimanagilbert.com/pro-images/MindAnchor-Login.png",
      "https://nsanzimanagilbert.com/pro-images/webTRC.jpg",
  ]);
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    window.setTimeout(scrollToTop(), 0);
    return { images };
  },
};
</script>

<style scoped>
.proShowCase {
  padding: 100px 0;
}
.proName {
  font-size: 2rem;
}
.proDesc {
  margin-top: 50px;
}
.proDesc p {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .proName {
    font-size: 1.2rem;
  }
}
</style>
